import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import ProcessSteps from "../components/sell-equipment/process-steps"
import InquiryForm from "../components/buy-equipment/inquiry-form"

import TECHNICIAN_ICON from "../images/content/technician.jpg"

const SellEquipment = () => (
  <Layout className="main inner-page-with-banner">
    <SEO
      title={`Unlock Hidden Value: Free Lab Equipment Assessment for Quick Resale Opportunities`}
      description={`Get a free lab equipment assessment to discover the resale value of your lab tools. Maximize profits, save time, and contribute to a greener lab!`}
    />

    <div className="row column">
      <br />
      <br />
      <h1>
        Unlock Hidden Value: Free Equipment Assessment for Quick Resale
        Opportunities
      </h1>
      <div className="row">
        <div className="medium-7 columns medium-push-5">
          <InquiryForm
            // title={`Tell us about your equipment: `}
            formSource={`Sell Equipment`}
          />
        </div>
        <div className="medium-5 columns medium-pull-7 mt5">
          <h2>Is Your Equipment Hiding Value?</h2>
          <p>
            <p>
              Many labs overlook the potential value of their unused or outdated
              lab, processing and packaging equipment. Whether you’re upgrading, downsizing, or simply
              reevaluating your needs,{" "}
              <strong>our free equipment assessment</strong> will reveal the
              true worth of your assets. Our expert team carefully analyzes your
              tools and provides a comprehensive report on their resale
              potential, ensuring you get the most out of your unused equipment.
            </p>
            <p>
              Don’t let valuable equipment gather dust in your storage rooms.
              Fill out the form to get started and discover how much you could
              earn!
            </p>
          </p>
          <img
            src={TECHNICIAN_ICON}
            width={285}
            height={285}
            alt="Technician performing a detailed assessment of lab equipment for resale potential in a warehouse setting."
          />
        </div>
      </div>
      <div className="row">
        <br />
        <h2>Precision in Every Assessment</h2>
        <p>
          <p>
            Looking to buy or sell used lab equipment? Surplus Solutions is your
            global partner in surplus asset management, with over 20 years of
            experience in the pharmaceutical and biotech industries. We
            specialize in helping you buy and sell used lab, processing, and
            packaging equipment sourced from leading manufacturers such as
            Agilent, Waters, Thermo Fisher Scientific, GEA,Cytiva, Sartorius,
            Beckman Coulter, and others.
          </p>
          <p>
            If you’re selling used lab equipment, we offer flexible solutions
            tailored to meet your exact needs. Whether through brokerage,
            consignment, auction, or instant cash purchases, we guide you every
            step of the way to ensure you get the maximum value for your assets.
            From appraisal to logistics, we manage every detail, saving you
            valuable time and resources so you can focus on growing your
            business.
          </p>
        </p>

        <h2>Maximize Value with Precision Assessment</h2>
        <p>
          <p>
            Our highly trained technicians carefully assess each piece of lab
            equipment to determine its resale potential. With years of
            experience in the industry, our team knows exactly what buyers are
            looking for and how much your equipment is worth.
          </p>
          <p>
            By participating in our free lab equipment assessment, you're not
            only unlocking the hidden value of your instruments, but you’re also
            contributing to a greener lab environment by extending the lifecycle
            of equipment that might otherwise be discarded. With a focus on
            sustainability and profitability, our service ensures that your
            unused lab instruments don't go to waste.
          </p>
        </p>
      </div>
      <div className="row">
        <hr/>
        <ProcessSteps />
      </div>
    </div>
  </Layout>
)

export default SellEquipment
