import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"

import BROKERAGE_ICON from "../../images/template/services/brokerage.jpg"
import CONSIGNMENT_ICON from "../../images/template/services/consignment.jpg"
import AUCTION_ICON from "../../images/template/services/auctions.jpg"
import PURCHASE_ICON from "../../images/template/services/purchase.jpg"

const ProcessSteps = () => {
  const tabs = useRef(null)

  useEffect(() => {
    $(tabs.current).easyResponsiveTabs({
      type: "horizontal",
      closed: false,
      tabidentify: "js-tabs-a", // The tab groups identifier
    })
  })

  return (
    <>
      <div className="row center mt6 mb8">
        <h2>Our Process to Buy & Sell Equipment</h2>
      </div>

      <div className="row center mt8 mb8 buy-sell-process">
        <div id="js-tabs-horizontal" ref={tabs}>
          <ul className="resp-tabs-list js-tabs-a resp-htabs">
            <li>
              <span className="thenumber">1</span>OnSite Inspection
            </li>
            <li>
              <span className="thenumber">2</span>Upfront Pricing
            </li>
            <li>
              <span className="thenumber">3</span>Selling Channel
            </li>
            <li>
              <span className="thenumber">4</span>Return On Investment
            </li>
          </ul>
          <div className="resp-tabs-container js-tabs-a">
            <div className="row left-align">
              <p>
                <p>
                  W
                  <span>
                    e have unmatched expertise in lab, processing and packaging
                    equipment, allowing us to accurately assess market demand
                    and value. Our certified equipment specialists will:
                    <li>Inspect and photograph your surplus assets</li>
                    <li>Provide detailed specification reports</li>
                    <li>Save you time by managing all assessments onsite</li>
                  </span>
                </p>
              </p>
            </div>
            <div className="row left-align">
              <p>
                <p>
                  Based on our thorough inspection,
                  <span>
                    {" "}
                    we offer either upfront cash offers or consignment pricing,
                    tailored to your chosen disposition method. There are no
                    storage or maintenance fees on equipment sold "as-is."
                  </span>
                </p>
              </p>
            </div>
            <div>
              <div className="row">
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={BROKERAGE_ICON}
                        alt="Brokerage"
                        title="Brokerage"
                        width="300"
                        height="300"
                      />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Brokerage</h5>
                      <p>
                        Our deep industry knowledge and extensive network allow
                        us to provide market insights, ensuring maximum returns
                        on your equipment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={PURCHASE_ICON}
                        alt="Outright Purchase"
                        title="Outright Purchase"
                        width="300"
                        height="300"
                      />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Outright Purchase</h5>
                      <p>
                        We purchase single pieces or entire facilities outright.
                        Our hassle-free transactions and logistics coordination
                        ensure quick sales.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      {" "}
                      <img
                        src={CONSIGNMENT_ICON}
                        alt="Consignment"
                        title="Consignment"
                        width="300"
                        height="300"
                      />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align ">
                      <h5 className="caps blue mb0">Consignment</h5>
                      <p>
                        Free up space while we securely store and sell your
                        equipment. Track your assets and enjoy hands-off
                        management.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="small-12 medium-6 columns mb8">
                  <div className="row">
                    <div className="xsmall-4 columns">
                      <img
                        alt="Auctions"
                        src={AUCTION_ICON}
                        width="300"
                        height="300"
                      />
                      <br />{" "}
                    </div>
                    <div className="xsmall-8 columns left-align">
                      <h5 className="caps blue mb0">Auctions</h5>
                      <p>
                        Our global <a href="/auctions">auction</a> channels
                        offer fast sales with high returns. Whether through
                        onsite, webcast, or sealed bids, we tailor the auction
                        to your needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>
                <div className="row left-align">
                  Whichever method you choose, we cover all upfront logistics
                  and inventory costs. You pay nothing. With outright purchases,
                  you’re paid before equipment leaves your facility, and with
                  consignment, we market your equipment immediately.
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProcessSteps
